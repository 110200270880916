import Swal from "sweetalert2";

export default Swal.mixin({
  showConfirmButton: false,
  showCancelButton: false,
  showCloseButton: false,
  allowEnterKey: false,
  allowEscapeKey: false,
  allowOutsideClick: false,
  animation: false,
  customClass: {
    popup: "no-bg",
    header: "no-bg",
    container: "no-bg",
    title: "no-bg"
  },
  html: `<div class="preloader"><div class="preloader-spin"></div></div>`
});
