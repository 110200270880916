import Swal from "sweetalert2";
import router from "../router";

export function updateSuccess(value) {
  Swal.fire({
    toast: true,
    position: "top-end",
    icon: "success",
    title: "Cập nhật thành công!",
    showConfirmButton: false,
    timer: 1000
  }).then(res => {
    if (res.dismiss === "timer") {
      router.push(`/${value}`);
    }
  });
}

export function createSuccess(value) {
  Swal.fire({
    toast: true,
    position: "top-end",
    icon: "success",
    title: "Tạo mới thành công!",
    showConfirmButton: false,
    timer: 1000
  }).then(res => {
    if (res.dismiss === "timer" || res.isDismissed) {
      router.push(`/${value}`);
    }
  });
}
