<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-6 mx-auto">
      <KTPortlet title="Tạo mới Gift Card" headSize="md">
        <template v-slot:body>
          <b-form @submit.prevent="onSubmit">
            <!-- <b-form-group id="input-group-1" label="Tên / Mã Gift Card" label-for="input-1">
          <b-form-input id="input-1" type="text" v-model="form.code"></b-form-input>
        </b-form-group> -->
            <b-form-group
              id="input-group-2"
              label="Tên khách hàng"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                type="text"
                v-model="form.customerName"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-3"
              label="Giá trị"
              label-for="input-3"
            >
              <b-form-input
                id="input-3"
                type="number"
                required
                v-model="form.value"
              ></b-form-input>
            </b-form-group>
            <!-- <b-form-group label="Tiệm">
          <v-select :options="allOutlets" label="name" v-model="outLetsSelected">
             <template v-slot:no-options="{ allOutlets }">
                <em v-if="!allOutlets">Không tìm thấy tiệm nào</em>
            </template>
          </v-select>
        </b-form-group> -->
            <b-button type="submit" variant="primary">Hoàn thành</b-button>
          </b-form>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>

<style lang="scss">
@import "./GiftCard.scss";
</style>

<script>
import KTPortlet from "@/components/Portlet.vue";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import Swal from "sweetalert2";
import axios from "@/common/api.js";
import Loader from "@/common/loader";
import { createSuccess } from "@/helper/index";
import router from "../../router";

export default {
  components: {
    KTPortlet,
    vSelect
  },
  data() {
    return {
      form: {
        code: "",
        price: 0,
        value: 0,
        outletId: "",
        customerName: ""
      }
    };
  },
  // computed: mapGetters(["allOutlets"]),

  created() {
    this.form.outletId = localStorage.getItem("outletId");
    this.$store.dispatch("resetListOutlets").then(() => {
      this.getListOutlet();
    });
  },

  methods: {
    onSubmit() {
      if (this.form.value > 500) {
        return Swal.fire({
          text: "Không thể tạo Gift Card với giá trị lớn hơn 500",
          icon: "error",
          confirmButtonText: "Đóng"
        });
      }

      Loader.fire();
      this.form.price = this.form.value;

      if (!this.form.code) delete this.form.code;
      if (!this.form.customerName) delete this.form.customerName;
      axios
        .post("/secure/r2/gift-card", this.form)
        .then(res => {
          Swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Tạo mới thành công!",
            showConfirmButton: false,
            timer: 1000
          }).then(res => {
            router.push(`/gift-card`);
          });
        })
        .catch(e => {
          Swal.fire({
            icon: "error",
            title: "Tạo mới không thành công!",
            text: e.errors
          });
        })
        .finally(Loader.close);
    },
    getListOutlet() {
      this.$store.dispatch("setOutlets", {});
    }
  }
};
</script>
